*,
*:before,
*:after {
    box-sizing: border-box;
}

html {
    overflow-y: scroll;
}

body {
    background: #24353f;
    font-family: 'Titillium Web', sans-serif;
}

a {
    text-decoration: none;
    color: #1ab188;
    transition: 0.5s ease;
}

a:hover {
    color: #179b77;
}

h1 {
    text-align: center;
    color: #fff;
    font-weight: 300;
    margin: 0 0 40px;
}

label {
    position: absolute;
    transform: translateY(6px);
    left: 13px;
    color: rgba(255, 255, 255, .5);
    transition: all 0.25s ease;
    pointer-events: none;
    font-size: 22px;
}

input,
textarea {
    font-size: 22px;
    display: block;
    width: 100%;
    height: 100%;
    padding: 8px 10px;
    background: none;
    background-image: none;
    border: 1px solid #a0b3b0;
    color: #fff;
    border-radius: 0;
    transition: border-color 0.25s ease, box-shadow 0.25s ease;
}

input:focus,
textarea:focus {
    outline: 0;
    border-color: #1ab188;
}

textarea {
    border: 2px solid #a0b3b0;
    resize: vertical;
}

.bg-light {
    background-color: #29d0a2 !important;
}

.navbar-brand {
    text-align: center;
    color: #fff !important;
    font-weight: 300;
    font-size: 2rem;
}

.nav-link , .nav-hi-user{
    color: #fff !important;
    font-size: 1.5rem;
}
.nav-hi-user{
    padding: 0.5rem 1rem 1rem 0;
}
.nav-link:hover {
    color: rgb(233, 222, 222) !important;
    font-size: 1.5rem;
}

.add {
    font-family: 'Source Sans Pro', sans-serif;
    /* background-color: #fff; */
    font-size: 20px;
    transition: 0.2s;
    color: #fff;
    box-shadow: 0px 23px 93px 1px rgba(0, 0, 0, 0.75);
}

.add:hover {
    background-color: #8BC34A;
    color: #fff;
}

.note {
    background: linear-gradient(#F9EFAF, #F7E98D);
    transition: all 0.3s;
}

.note:hover {
    box-shadow: 0px 23px 93px 1px rgba(0, 0, 0, 0.75);
}

/* .note a {
    color: black;
    font-size: 23px;
} */

.note i {
    color: black;
    font-size: 23px;
    cursor: pointer;
    transition: all 0.3s;
}

.del:hover {
    color: #F44336;
    transform: rotate(-110deg);
}

.edit:hover {
    color: #07f542;
    transform: rotate(-50deg);
}